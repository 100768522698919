import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        },
        {
            path: '/loten',
            name: 'Empty',
            component: () =>
            {
                return import( /* webpackChunkName: "tickets" */ '@/app/pages/Empty' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    let trackingAlias = {
        '/': '/landing',
        '/loten': '/loten',
        '/form': '/gegevens',
        '/form/1': '/gegevens-1',
        '/form/2': '/gegevens-2',
        '/form/3': '/gegevens-3',
        '/overzicht': '/overzicht',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    next();
});

export default appRouter;
