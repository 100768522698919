<template>
    <div class="main-layout">
        <div class="top-wrapper">
            <div class="lotto-logo">
                <a href="https://lotto.nederlandseloterij.nl/" target="_blank">
                    <img src="/static/img/logos/lotto.png" />
                    <p>
                        <svg class="arrow">
                            <use xlink:href="#icon-arrow-right" href="#icon-arrow-right"></use>
                        </svg>
                        terug naar lotto.nl
                    </p>
                </a>
            </div>
        </div>

        <div class="content">
            <slot></slot>
        </div>

        <footer>
            <div class="usp-bar">
                <ul class="large-flex">
                    <li>Wekelijks kans op de Vaakst Vallende Jackpot in Nederland</li>
                    <li>Lotto jackpot is minimaal &euro;&nbsp;2.500.000,-</li>
                    <li>In 2021 organiseren we vier exclusieve abonnee acties!</li>
                </ul>
            </div>
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <img class="big" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer small">Voor het organiseren van Lotto is aan Lotto B.V. vergunning verleend door de Kansspelautoriteit, kenmerk 10510 d.d. 23 december 2016. Trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" :href="'/static/pdf/actievoorwaarden.pdf'" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { tracking } from '@/app/mixins';
    import { gsap } from '@/vendor';

    export default {
        components: {
        },

        mixins: [ tracking ],

        data()
        {
            return {
            };
        },

        computed: {
        },

        mounted()
        {
            this.usptl = gsap.timeline({ repeat: -1, repeatDelay: 1, paused: true });
            this.usptl.set( '.usp-bar li', { autoAlpha: 0, display: 'none' }, 0.01 );
            this.usptl.fromTo( '.usp-bar li:nth-of-type(1)', { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'list-item', repeat: 1, yoyo: true, repeatDelay: 3 }, 0 );
            this.usptl.fromTo( '.usp-bar li:nth-of-type(2)', { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'list-item', repeat: 1, yoyo: true, repeatDelay: 3 }, 5 );
            this.usptl.fromTo( '.usp-bar li:nth-of-type(3)', { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'list-item', repeat: 1, yoyo: true, repeatDelay: 3 }, 10 );

            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                if( window.innerWidth < 940 )
                {
                    if( !this.usptl.isActive() )
                    {
                        this.usptl.play( 0 );
                    }
                }
                else
                {
                    this.usptl.pause( 0 );
                    gsap.set( '.usp-bar li', { display: 'list-item', autoAlpha: 1 });
                }
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            },

            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            }
        }
    };
</script>
